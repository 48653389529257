import { InputNumber, Tag, Typography as AntdTypography } from 'antd';
import { InputMoney } from 'common/components';
import { DeleteIcon, EditIcon } from 'common/icons';
import styled from 'styled-components';
import { NeutralColors } from 'theme';

interface ContainerProps {
  hidden?: boolean;
}

const Container = styled.div<ContainerProps>`
  margin-top: 16px;
  padding: 16px;
  border: 1px solid ${NeutralColors[20]};
  border-radius: 8px;
  display: ${(props) => (props.hidden ? 'none' : 'block')};
`;

const ContainerFlex = styled.div`
  display: flex;
`;

const ContainerFlexBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const BoxTrash = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${NeutralColors[20]};
  border-radius: 8px;
  width: 32px;
  height: 32px;
  cursor: pointer;
`;

const TagWarehouse = styled(Tag)`
  margin-left: 8px;
`;

const InputUnitValue = styled(InputMoney)`
  width: 152px;
  height: 40px;
`;

const InputQty = styled(InputNumber)`
  width: 152px;
  height: 40px;
`;

const IconWrapper = styled.div`
  width: 22px;
  height: 22px;
  border-radius: 4px;
  padding: 2px;
  background-color: ${NeutralColors[10]};
  color: ${NeutralColors[60]};
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  height: 44px;
`;

const Title = styled(AntdTypography.Title)`
  margin-bottom: 0 !important;
`;

const DeleteIconCustom = styled(DeleteIcon)`
  width: 20px;
  height: 20px;
`;

const EditIconCustom = styled(EditIcon)`
  width: 20px;
  height: 20px;
`;

export {
  BoxTrash,
  Container,
  ContainerFlex,
  ContainerFlexBetween,
  DeleteIconCustom,
  EditIconCustom,
  IconWrapper,
  InfoWrapper,
  InputQty,
  InputUnitValue,
  TagWarehouse,
  Title,
  TitleWrapper,
};
